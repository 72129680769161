

const linkPattern = /\[\[[^\[\]]+\]\[([^\[\]]+)\]\]/

/**
  * @param {string} headline
  * @returns {string}
  **/
export function orgRemoveLink(headline) {

  if (!headline.trim().length) {
    return ''
  }

  return headline.replace(linkPattern, "$1");
}
