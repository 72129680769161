<template>
  <div data-title="week-calendar" class="px-6">
    <div class="border-b-black border-b border-solid flex py-5">
      <span class="flex-1 space-x-1">
        <span>一</span>
        <span>Mon</span>
      </span>
      <span class="flex-1 space-x-1">
        <span>二</span>
        <span>Tue</span>
      </span>
      <span class="flex-1 space-x-1">
        <span>三</span>
        <span>Wen</span>
      </span>
      <span class="flex-1 space-x-1">
        <span>四</span>
        <span>Thu</span>
      </span>
      <span class="flex-1 space-x-1">
        <span>五</span>
        <span>Fri</span>
      </span>
      <span class="flex-1 space-x-1">
        <span>六</span>
        <span>Sat</span>
      </span>
      <span class="flex-1 space-x-1">
        <span>日</span>
        <span>Sun</span>
      </span>
    </div>
    <div class="flex pt-3">
      <span class="flex-1 px-2 space-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.mon">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
      <span class="flex-1 px-2 space-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.tue">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
      <span class="flex-1 px-2 space-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.wen">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
      <span class="flex-1 px-2 pace-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.thu">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
      <span class="flex-1 px-2 space-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.fri">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
      <span class="flex-1 px-2 space-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.sat">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
      <span class="flex-1 px-2 space-x-1">
        <ul class="list-disc">
          <li v-for="todo of weekAgenda.sun">
            {{ removeLink(todo.headline) }}
          </li>
        </ul>
      </span>
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, ref } from "vue";
import { orgRemoveLink as removeLink } from "../filters/org-link";

export default defineComponent({
  setup() {
    const weekAgenda = ref({
      mon: [],
      tue: [],
      wen: [],
      thu: [],
      fri: [],
      sat: [],
      sun: [],
    });

    onBeforeMount(() => {
      fetch("/njs/org")
        .then((res) => res.json())
        .then((json) => (weekAgenda.value = json));
    });

    return { weekAgenda, removeLink };
  },
});
</script>
