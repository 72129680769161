<template>
  <div v-if="loaded" class="text-white text-lg absolute right-12 top-4 space-x-2">
    <span>{{ weather.city }}</span>
    <span>{{ weather.temperature }}&#x00b0;C</span>
    <i :class="['owi', weather.icon_cls]"></i>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, reactive, ref } from 'vue';

export default defineComponent({
  setup() {
    const weather = reactive({
      city: 'Chengdu',
      temperature: NaN,
      icon_cls: '',
    })

    const loaded = ref(false);


    onBeforeMount(() => {
      navigator?.geolocation?.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords

        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=a3975c8de7c667a6b38347648f34075f&lang=zh_cn&&units=metric`, { method: 'GET', mode: 'cors' })
          .then(res => {
            return res?.json()
          })
          .then((data) => {
            weather.temperature = data?.main?.temp;
            weather.icon_cls = `owi-${data.weather[0]?.icon}`;
            weather.city = data?.name;
            loaded.value = true;
          })
      }, (e) => { console.error(e) }, { timeout: 10 * 1000 })
    })


    return { weather, loaded };
  }
})


</script>
