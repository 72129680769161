import { createApp, h } from "vue";
import DashBoardPanel from "./components/dashboard-panel.vue";
import { orgRemoveLink } from "./filters/org-link.js";

const app = createApp({
  created() {
    const bgImgSrc =
      "https://azzgo.top:8443/nextcloud/s/bN8aMbB5RfsEzoo/preview";
    document.body.style.backgroundImage = `url(${bgImgSrc})`;
  },
  render() {
    return h(DashBoardPanel);
  },
});

app.mount("#app");
