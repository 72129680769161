<template>
  <div class="main backdrop-blur-[6px] h-[100vh] box-border flex justify-center items-center">
    <div class="py-5 px-7 bg-slate-50 bg-opacity-80 min-h-fit h-[80%] w-[80%] shadow overflow-y-auto">
      <div class="flex">
        <Logo></Logo>
        <div>
          <AppEntry v-for="app in apps" :icon_src="app.icon_src" :name="app.name" :href="app.href">
          </AppEntry>
        </div>
      </div>
      <WeekCalendar></WeekCalendar>
    </div>
    <WeatherWidget />
  </div>
</template>

<script type="javascript">
const { defineComponent } = require("vue");
import Logo from './logo.vue'
import WeekCalendar from './week-calender.vue';
import AppEntry from './app-entry.vue';
import Deposit_ICON from '../../img/Deposit_ICON.png';
import WeatherWidget from './weather-widget.vue';

export default defineComponent({
  name: "dashboard-panel",
  components: {
    Logo,
    WeekCalendar,
    AppEntry,
    WeatherWidget,
  },
  data() {
    return {
      apps: [
        {
          name: 'Deposit',
          icon_src: Deposit_ICON,
          href: "https://azzgo.top:8443/deposit-app/"
        }
      ]
    }
  }
});
</script>
