<template>
  <div @click="goTo" class="flex flex-col items-center justify-between py-2 w-[150px] h-[150px] bg-[#FF9966] rounded shadow-[0_4px_4px_rgba(0,0,0,0.25)] hover:shadow-lg hover:bg-opacity-80 cursor-pointer">
    <img class="w-[100px] max-h-[85px]" :src="icon_src" :alt="name + ' icon'" />
    <p class="text-white">{{name}}</p>
  </div>
</template>

<script>
export default {
  name: "AppEntry",
  props: {
    name: String,
    icon_src: String,
    href: String,
  },
  methods: {
    goTo() {
      location.assign(this.href);
    }
  }
}
</script>
